import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const GET_USER = "getUser";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_USER = "setUserData";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {

  errors: null,
  user: JwtService.getUser() || "",
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.authenticate("api/auth/login", credentials)
        .then(function(response) {
          context.commit(SET_AUTH, response);
          resolve(response);
        })
        .catch(error => {
          Swal.fire({
            text: error.response.data.b,
            icon: "error",
            heightAuto: false
          });
          // context.commit(SET_ERROR, "Error");
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("oauth", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      // ApiService.get("verify")
      //     .then(({ data }) => {
      context.commit(SET_USER, context.state.user);
      // })
      // .catch(({ response }) => {
      //     context.commit(SET_ERROR, response.data.errors);
      // });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [GET_USER](context) {
    if (JwtService.getUser()) {
      // ApiService.get("verify")
      //     .then(({ data }) => {
      context.commit(SET_USER, context.state.user);
      // })
      // .catch(({ response }) => {
      //     context.commit(SET_ERROR, response.data.errors);
      // });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, response) {
    state.isAuthenticated = true;
    state.user = response.data.data;
    state.errors = {};
    JwtService.saveToken(response.data.access_token);
    // JwtService.saveUser(response.data.is_admin);
    ApiService.setHeader(response.data.access_token);
  },
  [SET_USER](state, response) {
    state.user = response;
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};

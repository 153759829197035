import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import Constants from "@/config/config.js";
import { LOGOUT } from "@/core/services/store/auth.module";
import router from "/src/router";
import store from "@/core/services/store";

/**
 * Service to call HTTP request via Axios
 */
var tokn = null;

var handleServerError = function(error) {
  var errors = error.response.data.error;
  switch (error.response.status) {
    case 400:
      if(errors!=undefined){
        Object.keys(errors).forEach(key => {
          Vue.$toast.open({
            message: errors[key][0],
            type: "error"
            // all of other options may go here
          });
        });
      }else{
        Vue.$toast.open({
          message: error.response.data.message,
          type: "error"
          // all of other options may go here
        });
      }
      // throw `${error}`
      break;
    case 401:
    case 408:
      // logged out
      store.dispatch(LOGOUT).then(() => router.push({ name: "login" }));
      break;
    default: throw `${error}`;
  }
}

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = Constants.API_BASE_URL;
    //
  },

  /**
   * Set the default HTTP request headers
   */

  setAuth() {
    Vue.$log.error("setAuth has been deprecated no need to call")
    return false;
  },
  setHeader(token) {
    JwtService.destroyToken();
    tokn = token;
    JwtService.saveToken(tokn ? tokn : "");
  },
  query(resource, params) {
    return Vue.axios.get(resource, params).catch(() => {
      // throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource,config) {
    return Vue.axios.get("/" + resource,{
      headers:{
        "Authorization":`Bearer ${JwtService.getToken()}`,
      },
      ...config
    }).catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        handleServerError(error);
        
      } else {
        // Something happened in setting up the request that triggered an Error
        throw error;
      }
      
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post("/" + resource, params,{
      headers:{
        "Authorization":`Bearer ${JwtService.getToken()}`,
      }
    }).catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        handleServerError(error);
        
      } else {
        // Something happened in setting up the request that triggered an Error
        throw error;
      }
    });
  },
  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params ) {
    return Vue.axios.put("/" + resource, params,{
      headers:{
        "Authorization":`Bearer ${JwtService.getToken()}`
      }
    }).catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        handleServerError(error);
        
      } else {
        // Something happened in setting up the request that triggered an Error
        throw error;
      }
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete("/" + resource,{
      headers:{
        "Authorization":`Bearer ${JwtService.getToken()}`
      }
    }).catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        handleServerError(error);
        
      } else {
        // Something happened in setting up the request that triggered an Error
        throw error;
      }
    });
  },
  deleteBody(resource, params) {
    return Vue.axios.delete("/" + resource,{data:params,
      headers:{
        "Authorization":`Bearer ${JwtService.getToken()}`
      }
    }).catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        handleServerError(error);
        
      } else {
        // Something happened in setting up the request that triggered an Error
        throw error;
      }
    });
  },

  authenticate(resource, credtionals) {
    // var data = {
    //   grant_type: "password",
    //   password: credtionals.password,
    //   user_name: credtionals.email
    // };
    // var headers = {
    //   "Content-Type": "application/x-www-form-urlencoded",
    //   Accept: "application/json"
    // };
    const formData = new FormData();
    formData.append("user_type", "admin");
    formData.append("email", credtionals.email);
    formData.append("password", credtionals.password);

    return Vue.axios.post(`/${resource}`,formData).catch(error => {
      var status = error.response.data.code;
      var message = error.response.data.message;
      switch (status) {
        case 400:
          //   case 402:
          //   case 408:
          // case 500:
          // if (params.showAlert) {
          //     alertify.alert((data.code || "Error").toString(), data.message || "We have found some technical difficulties. Please contact admin.");
          // }
          break;
        default:
          // if (params.showAlert) {
          //     alertify.alert("Error", "We have found some technical difficulties. Please contact admin.");
          // }
          Swal.fire({
            title: "",
            text: message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          throw `${error}`;
      }
      // throw (error);
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put("/" + resource, params,{
      headers:{
        "Authorization":`Bearer ${JwtService.getToken()}`
      }
    }).catch(error => {
      var status = error.response.data.code;
      var message = error.response.data.message;
      switch (status) {
        case 400:
          // case 402:
          // case 408:
          // case 500:
          // if (params.showAlert) {
          //     alertify.alert((data.code || "Error").toString(), data.message || "We have found some technical difficulties. Please contact admin.");
          // }
          break;
        default:
          // if (params.showAlert) {
          //     alertify.alert("Error", "We have found some technical difficulties. Please contact admin.");
          // }
          Vue.$toast.open({
            message: message,
            type: "error"
            // all of other options may go here
          });
          throw `${error}`;
      }
    });
  }
};

export default ApiService;

const ID_TOKEN_KEY = "id_token";
const ID_USER = "id_user";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};
export const getUser = () => {
  return window.localStorage.getItem(ID_USER);
};

export const saveUser = user => {
   window.localStorage.setItem(ID_USER, user);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  //window.localStorage.removeItem(ID_USER);
};

export default { getToken, saveToken, destroyToken, getUser, saveUser };

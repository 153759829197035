import Vue from "vue";
import Router from "vue-router";
import Store from "./core/services/store/index";

Vue.use(Router);
const routes = [{
        path: "/",
        base: process.env.VUE_APP_API_BASE_URL,
        redirect: "/dashboard",
        component: () =>
            import ("@/view/layout/Layout"),
        meta: { requiresAuth: true },
        children: [
            {
            path: "/dashboard",
            name: "dashboard",
            component: () =>
                import ("@/view/pages/Dashboard.vue")
        },
        //product start
        {
            path: "products",
            name: "products",
            component: () =>
                import ("@/view/pages/product/ProductList.vue")
        },
        {
            path: "product/basic/:id?",
            name: "product-basic",
            component: () =>
                import ("@/view/pages/product/form/Basic.vue")
        },
        {
            path: "product/data/:id",
            name: "product-form-data",
            component: () =>
                import ("@/view/pages/product/form/Data.vue")
        },
        {
            path: "product/attribute/:id",
            name: "product-form-attribute",
            component: () =>
                import ("@/view/pages/product/form/Attribute.vue")
        },
        {
            path: "product/link/:id",
            name: "product-form-link",
            component: () =>
                import ("@/view/pages/product/form/Link.vue")
        },
        {
            path: "product/filter/:id",
            name: "product-form-filter",
            component: () =>
                import ("@/view/pages/product/form/Filter.vue")
        },
        {
            path: "product/glass-usage/:id",
            name: "product-form-glass-usage",
            component: () =>
                import ("@/view/pages/product/form/GlassUsage.vue")
        },
        {
            path: "product/lens-package/:id",
            name: "product-form-lens-package",
            component: () =>
                import ("@/view/pages/product/form/LensPackage.vue")
        },
        {
            path: "product/options/:id",
            name: "product-options",
            component: () =>
                import ("@/view/pages/product/form/Options.vue")
        },
        {
            path: "product/option-form/:id/:vid?",
            name: "product-form-options",
            component: () =>
                import ("@/view/pages/product/form/OptionForm.vue")
        },
        {
            path: "product/faq/:id",
            name: "product-faq",
            component: () =>
                import ("@/view/pages/product/form/Faq.vue")
        },
        {
            path: "product/faq-form/:id/:qid?",
            name: "product-form-faq",
            component: () =>
                import ("@/view/pages/product/form/FaqForm.vue")
        },
        {
            path: "product/image-upload/:id",
            name: "product-form-image-upload",
            component: () =>
                import ("@/view/pages/product/form/Image.vue")
        },

        {
            path: "product/internal-links/:id?",
            name: "product-form-internal-link",
            component: () =>
                import ("@/view/pages/product/form/SeoLink.vue")
        },
        //product End
        //attributes start
        {
            path: "attribute",
            name: "attributes",
            component: () =>
                import ("@/view/pages/attribute/Attribute.vue")
        },
        {
            path: "attribute/form",
            name: "attribute-form",
            component: () =>
                import ("@/view/pages/attribute/AttributeForm.vue")
        },
        {
            path: "attribute/form/:id",
            name: "attribute-form-edit",
            component: () =>
                import ("@/view/pages/attribute/AttributeForm.vue")
        },
        {
            path: "attribute-group",
            name: "attribute-group",
            component: () =>
                import ("@/view/pages/attribute/AttributeGroup.vue")
        },
        {
            path: "attribute-group/form",
            name: "attribute-group-form",
            component: () =>
                import ("@/view/pages/attribute/AttributeGroupForm.vue")
        },
        {
            path: "attribute-group/:id",
            name: "attribute-group-form-edit",
            component: () =>
                import ("@/view/pages/attribute/AttributeGroupForm.vue")
        },
        //attribute end
        //filter start
        {
            path: "filter",
            name: "filter",
            component: () =>
                import ("@/view/pages/filter/Filter.vue")
        },
        {
            path: "filter/form",
            name: "filter-form",
            component: () =>
                import ("@/view/pages/filter/FilterForm.vue")
        },
        {
            path: "filter/form/:id",
            name: "filter-form-edit",
            component: () =>
                import ("@/view/pages/filter/FilterForm.vue")
        },
        {
            path: "filter-group",
            name: "filter-group",
            component: () =>
                import ("@/view/pages/filter/FilterGroup.vue")
        },
        {
            path: "filter-group/form",
            name: "filter-group-form",
            component: () =>
                import ("@/view/pages/filter/FilterGroupForm.vue")
        },
        {
            path: "filter-group/form/:id",
            name: "filter-group-form-edit",
            component: () =>
                import ("@/view/pages/filter/FilterGroupForm.vue")
        },
        //filter end
        //Category start
        {
            path: "category",
            name: "category",
            component: () =>
                import ("@/view/pages/category/Category.vue")
        }, 
        {
            path: "category/form",
            name: "category-form",
            component: () =>
                import ("@/view/pages/category/CategoryForm.vue")
        },
        {
            path: "category/form/:id",
            name: "category-form-edit",
            component: () =>
                import ("@/view/pages/category/CategoryForm.vue")
        },
        {
            path: "category/internal-links/:id?",
            name: "category-form-internal-link",
            component: () =>
                import ("@/view/pages/category/SeoLink.vue")
        },
        //Category end
        //Contact start
        {
            path: "contact",
            name: "contact",
            component: () =>
                import ("@/view/pages/contact/Contact.vue")
        }, 
        {
            path: "contact/form",
            name: "contact-form",
            component: () =>
                import ("@/view/pages/contact/ContactForm.vue")
        },
        {
            path: "contact/form/:id",
            name: "contact-form-edit",
            component: () =>
                import ("@/view/pages/contact/ContactForm.vue")
        },
        //contact end
        //Lens Price Group start
        {
            path: "lens-price",
            name: "lens-price",
            component: () =>
                import ("@/view/pages/lens/LensPrice.vue")
        }, 
        {
            path: "lens-price/form",
            name: "lens-price-form",
            component: () =>
                import ("@/view/pages/lens/LensPriceForm.vue")
        },
        {
            path: "lens-price/form/:id",
            name: "lens-price-form-edit",
            component: () =>
                import ("@/view/pages/lens/LensPriceForm.vue")
        },
        //Lens Price Group end
        //Inventory start
        {
            path: "inventory",
            name: "inventory",
            component: () =>
                import ("@/view/pages/inventory/Inventory.vue")
        }, 
        {
            path: "inventory/form",
            name: "inventory-form",
            component: () =>
                import ("@/view/pages/inventory/InventoryForm.vue")
        },
        {
            path: "inventory/form/:id",
            name: "inventory-form-edit",
            component: () =>
                import ("@/view/pages/inventory/InventoryForm.vue")
        },
        //Inventory end

        // order start
        {
            path: "order",
            name: "orders",
            component: () =>
                import ("@/view/pages/order/Orders.vue")
        },
        {
            path: "order-detail/:id",
            name: "orderdetail",
            component: () =>
                import ("@/view/pages/order/OrderDetail.vue")
        },
        {
            path: "order-status-form/:id",
            name: "orderdetail",
            component: () =>
                import ("@/view/pages/order/OrderChangeStatusForm.vue")
        },
        // order end

         // return start
         {
            path: "return",
            name: "return",
            component: () =>
                import ("@/view/pages/return/Return.vue")
        },
        {
            path: "return-status-form/:id",
            name: "returnform",
            component: () =>
                import ("@/view/pages/return/OrderChangeStatusForm.vue")
        },
        // return end

        // Cancellation start
        {
            path: "cancellation",
            name: "cancellation",
            component: () =>
                import ("@/view/pages/cancel/Cancel.vue")
        },
        {
            path: "cancellation-status-form/:id",
            name: "cancellationform",
            component: () =>
                import ("@/view/pages/cancel/OrderChangeStatusForm.vue")
        },
        // Cancellation end

         // Cancellation start
         {
            path: "users",
            name: "user",
            component: () =>
                import ("@/view/pages/customer/Customer.vue")
        },
        // Cancellation end

        // review start
        {
            path: "review",
            name: "review",
            component: () =>
                import ("@/view/pages/review/Reviews.vue")
        },
        {
            path: "review-status-form/:id",
            name: "reviewdetail",
            component: () =>
                import ("@/view/pages/review/ReviewChangeStatusForm.vue")
        },
        // review end

        //Offers start
        {
            path: "offers",
            name: "offers",
            component: () =>
                import ("@/view/pages/offers/Offers.vue")
        }, 
        {
            path: "offer/form",
            name: "offer-form",
            component: () =>
                import ("@/view/pages/offers/OfferForm.vue")
        },
        {
            path: "offer/form/:id",
            name: "offer-form-edit",
            component: () =>
                import ("@/view/pages/offers/OfferForm.vue")
        },
        {
            path: "offer-product-add/:id",
            name: "offer-product-add",
            component: () =>
                import ("@/view/pages/offers/OfferProductForm.vue")
        },
        //Offers end

        //Blog start
        {
            path: "blog-author",
            name: "blog-author",
            component: () =>
                import ("@/view/pages/blog/author/Author.vue")
        }, 
        {
            path: "blog-author/form",
            name: "blog-author-form",
            component: () =>
                import ("@/view/pages/blog/author/AuthorForm.vue")
        },
        {
            path: "blog-author/form/:id",
            name: "blog-author-form-edit",
            component: () =>
                import ("@/view/pages/blog/author/AuthorForm.vue")
        },
        {
            path: "blog-category",
            name: "blog-category",
            component: () =>
                import ("@/view/pages/blog/category/Category.vue")
        }, 
        {
            path: "blog-category/form",
            name: "blog-category-form",
            component: () =>
                import ("@/view/pages/blog/category/CategoryForm.vue")
        },
        {
            path: "blog-category/form/:id",
            name: "blog-category-form-edit",
            component: () =>
                import ("@/view/pages/blog/category/CategoryForm.vue")
        },
        {
            path: "blogs",
            name: "blogs",
            component: () =>
                import ("@/view/pages/blog/Blog.vue")
        }, 
        {
            path: "blog/form",
            name: "blog-form",
            component: () =>
                import ("@/view/pages/blog/BlogForm.vue")
        },
        {
            path: "blog/form/:id",
            name: "blog-form-edit",
            component: () =>
                import ("@/view/pages/blog/BlogForm.vue")
        },
        {
            path: "comments/:id",
            name: "comments",
            component: () =>
                import ("@/view/pages/blog/Comment.vue")
        }, 
        {
            path: "reply-form/:id",
            name: "reply",
            component: () =>
                import ("@/view/pages/blog/ReplyForm.vue")
        }, 
        //Blog end

        {
            path: "settings/seo",
            name: "settings-seo",
            component: () =>
                import ("@/view/pages/settings/SeoForm.vue")
        },
    ]

    },
    {
        path: "/product",
        redirect: "/",
        component: () =>
            import ("@/view/layout/Layout"),
        children: [],
        meta: { requiresAuth: true }
    },
    {
        path: "/coupon",
        redirect: "/coupon",
        component: () =>
            import ("@/view/layout/Layout"),
        children: [{
            path: "/",
            name: "coupon",
            component: () =>
                import ("@/view/pages/coupon.vue")
        }],
        meta: { requiresAuth: true }

    },
    {
        path: "/bill",
        redirect: "/bill",
        component: () =>
            import ("@/view/layout/Reciept"),
        children: [{
            path: "/",
            name: "bill",
            component: () =>
                import ("@/view/pages/billForm.vue")
        },{
            path: "/print",
            name: "billPrint",
            component: () =>
                import ("@/view/pages/bill.vue")
        }]

    },
    {
        path: "/",
        component: () =>
            import ("@/view/pages/auth/login_pages/Login-1"),
        children: [{
                name: "login",
                path: "/login",
                component: () =>
                    import ("@/view/pages/auth/login_pages/Login-1")
            },
            {
                name: "register",
                path: "/register",
                component: () =>
                    import ("@/view/pages/auth/login_pages/Login-1")
            }
        ]
    },
    {
        path: "*",
        redirect: "/404"
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () =>
            import ("@/view/pages/error/Error-1.vue")
    }
]
const router =  new Router({
    routes: routes,
    mode: 'history'
});

// router.beforeEach((to,from,next) => {
//     if(!Store.state.auth.isAuthenticated && to.name != 'login'){
//         next('/login');
//     }else if(Store.state.auth.isAuthenticated && to.name == 'login'){
//         next('/dashboard');
//     }else{
//         next();
//     }
// })

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
      // Check if the user is authenticated
      const isAuthenticated = Store.state.auth.isAuthenticated;// your authentication logic here
  
      if (isAuthenticated) {
        next(); // Proceed to the protected route
      } else {
        next('/login'); // Redirect to the login page
      }
    } else {
      next(); // Proceed to the next route
    }
  });
  

export default router;
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import PrimeVue from 'primevue/config';
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import moment from "moment";
import vue2Dropzone from 'vue2-dropzone'


Vue.prototype.moment = moment;
Vue.use(require('vue-moment'));
Vue.use(PrimeVue);
Vue.use(vue2Dropzone);
Vue.component('vue-dropzone', vue2Dropzone);

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "primevue/resources/themes/mdc-light-indigo/theme.css"; //Prime Vue
import "primevue/resources/primevue.min.css"; //core css Prime Vue
import "@/assets/sass/_common.scss"; //Prime Vue icons
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

Vue.use(Treeselect);

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {
    // Ensure we checked auth before each page load.
    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

import vSelect from 'vue-select'

Vue.component('vue-select', vSelect);
import "vue-select/src/scss/vue-select.scss";

Vue.use(VueToast, {
    // One of the options
    position: "top-right",
    dismissible: true,
    duration: 3000
});

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app");